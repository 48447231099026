import React, { useCallback, useEffect, useState } from 'react';
import { useTranslate, TextField, FunctionField } from 'react-admin';
import CustomTable from '~components/CustomTable';
import { AFFILIATION_REQUESTS_FIELDS, EMAIL_CONFIG } from '~screens/AffiliationRequests/constants';
import { AffiliationRequest, Nullable } from '~utils/types';
import StatusField from '~screens/AffiliationRequests/components/StatusField';
import WithProps from '~components/WithProps';
import CustomModal from '~components/CustomModal';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import ActionField from '~screens/AffiliationRequests/components/ActionField';
import useModal from '~hooks/useModal';
import { getSendEmailTexts, isUserValidationPending } from '~screens/AffiliationRequests/utils';
import { useHistory } from 'react-router';
import { linkToRecord } from 'ra-core';

import { useStyles } from './styles';
import AffiliationRequestsFilter from './components/AffiliationRequestsFilter';
import ResponsiveList from './components/ResponsiveList';
import HistoricAffiliationButton from './components/HistoricAffiliationButton';
import HistoricAffiliationModal from './components/HistoricAffiliationModal';
import StatusRPA from './StatusRPA';
import TooltipPromoterName from './components/TooltipPromoterName';
import IndicatorBall from './components/ScoringIndicator/index';

interface Props {
  resource: string;
  state: string;
  rounded?: boolean;
  className?: string;
  labelClassName?: string;
  affiliationStatusTypes: string;
  rpaStatus: boolean;
  rpa_message: string;
  basePath: string;
}

interface RequestPayload {
  id?: number;
}

function AffiliationRequestsList({ resource, ...props }: Props) {
  const styles = useStyles();
  const history = useHistory();
  const t = useTranslate();
  const [affiliationState, setAffiliationState] = useState<Nullable<string> | undefined>(null);

  const handleRowClick = (id: any) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCallback(() => history.push(linkToRecord(props.basePath, id, 'show')), [id]);

  const handleOpen = useCallback((affiliation?: AffiliationRequest) => {
    setAffiliationState(affiliation?.state);
  }, []);

  const handleClose = useCallback(() => {
    setAffiliationState(null);
  }, []);

  const [, dispatchInitialRequest, loadingInitial, onOpenModalInitial] = useModal<
    AffiliationRequest,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING]
  });

  const [, dispatchValidationRequest, loadingValidation, onOpenModalValidation] = useModal<
    AffiliationRequest,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION]
  });

  const renderTypeField = (record: AffiliationRequest) => {
    if (record.affiliationType === 'convenio_8-360' || record.affiliationType === 'convenio 8-360') {
      return record.affiliationType && 'Convenio 8-360';
    } else if (
      record.affiliationType === 'obligatorio_8-360' ||
      record.affiliationType === 'obligatorio 8-360'
    ) {
      return record.affiliationType && 'Obligatorio 8-360';
    }
    return (
      record.affiliationType && t(`resources.affiliations.fields.affiliationTypes.${record.affiliationType}`)
    );
  };

  const renderScoringField = (record: AffiliationRequest) => {
    const scoreResult = record.scoringLevel || '';
    const affiliationId = record.id.toString();
    if (scoreResult == 'Restringido') {
      return null
    }
    return <IndicatorBall scoreResult={scoreResult} id={affiliationId} />;
  };

  useEffect(() => {
    localStorage.removeItem('planValidityDate');
  }, []);

  return (
    <WithProps {...{ resource, ...props }}>
      {(withProps: Props) => (
        <>
          <CustomTable
            {...withProps}
            title={t('resources.affiliations.listName')}
            filters={<AffiliationRequestsFilter />}
            showExport
            showDetail
            listComponent={ResponsiveList}
          >
            <TextField source={AFFILIATION_REQUESTS_FIELDS.id} sortable />
            <FunctionField
              label={t('resources.affiliations.fields.promoterId')}
              source={AFFILIATION_REQUESTS_FIELDS.promoterId}
              render={(affiliation: AffiliationRequest) => <TooltipPromoterName {...affiliation} />}
            />
            <TextField
              label={t('resources.affiliations.fields.firstName')}
              source={AFFILIATION_REQUESTS_FIELDS.firstName}
              sortable
            />
            <TextField
              label={t('resources.affiliations.fields.lastName')}
              source={AFFILIATION_REQUESTS_FIELDS.lastName}
              sortable
            />
            <TextField
              label={t('resources.affiliations.fields.document')}
              source={AFFILIATION_REQUESTS_FIELDS.documentNumber}
              sortable
            />
            <FunctionField label={t('resources.affiliations.fields.type')} render={renderTypeField} />
            <FunctionField label={t('resources.affiliations.fields.status')} render={StatusField} />
            <FunctionField label={t('Proceso RPA')} render={StatusRPA} />

            <FunctionField
              label={t('Impacto')}
              render={
                renderScoringField // Se renderiza el contenido solo si no es 'restringido'
              }
            />

            <FunctionField
              label={t('resources.affiliations.fields.action')}
              render={(affilliation: AffiliationRequest) => (
                <div className={styles.actions}>
                  <HistoricAffiliationButton
                    id={affilliation.id}
                    stateDatetime={affilliation.stateDatetime}
                  />
                  <ActionField
                    affiliation={affilliation}
                    onSendInitialEmailAction={onOpenModalInitial}
                    onResendUserValidationEmail={onOpenModalValidation}
                    onCheckDetail={handleRowClick(affilliation.id)}
                  />
                </div>
              )}
            />
          </CustomTable>
          {affiliationState && (
            <CustomModal
              title={t('resources.affiliations.modals.title')}
              modalName={EMAIL_CONFIG[affiliationState].modalName}
              onConfirm={
                isUserValidationPending(affiliationState) ? dispatchValidationRequest : dispatchInitialRequest
              }
              loading={isUserValidationPending(affiliationState) ? loadingValidation : loadingInitial}
              onClose={handleClose}
              showCloseIcon={false}
            >
              <p className={styles.content}>{t(getSendEmailTexts(affiliationState).modalMessage)}</p>
            </CustomModal>
          )}
          <HistoricAffiliationModal />
        </>
      )}
    </WithProps>
  );
}

export default AffiliationRequestsList;
