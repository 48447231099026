/* eslint-disable max-lines */
import { ReactNode } from 'react';
import { ReduxState } from 'ra-core';

import { IVBSteps } from './interfaces';

export type Nullable<T> = T | null;

export interface ErrorResponse {
  errors: Error[];
  details?: any;
}

export type SortParams = {
  field: string;
  order: string;
};

export interface Error {
  code: string;
  message: string;
  detail?: any;
}
export type AppState = ReduxState;

export interface Table {
  selectable: boolean;
  showDetail: boolean;
}

export interface TablePassing extends Table {
  children?: ReactNode[] | ReactNode;
  basePath?: string;
  data?: GenericObjectInterface<any>;
  listComponent: React.ComponentType<any>;
  ids?: Array<number>;
}

export interface Application {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface User {
  documentType: string;
  documentNumber: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface Attorney {
  documentType: string;
  documentNumber: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface FamilyMember {
  member: string;
  age: number;
  firstName?: string;
  cuil: string;
  sex: string;
  birthDate?: string;
}

export interface RejectedReason {
  type: string;
  reasons: Array<string>;
  age: number;
  index: number;
  sex?: string;
}

export interface Affiliation {
  id?: number;
  promoterId: number;
  affiliationType?: string;
  planValidityDate?: string;
  familyGroupDetails?: FamilyMember[];
  state: string;
  user: User;
  potentialRisk?: boolean;
  f1Document?: string;
  swornDocument?: string;
  attachments?: string[];
  stateDatetime?: string;
  hiringMode?: string;
  increasePlanAllowed: string;
  companyPlan?: string;
  isPaymentResponsible: string;
  details?: string;
  rpaStatus?: boolean;
  rpaPath?: string;
  planTypes?: string;
  vbSteps?: IVBSteps | undefined;
  createdAt?: string;
  applicationType: string;
  preSwornDocument?: string;
  lifeInsurance?: boolean;
  others?: string;
  termsAndCondDocument?: string;
  zipGawUrl?: string;
  lastAudit?: string;
  sourceApplication?: any;
}

export interface AffiliationRequest extends Affiliation {
  id: number;
  promoterFirstName: string;
  promoterLastName: string;
  promoter: Promoter;
  scoringLevel?: string;
}

export interface AffiliationRequestForm {
  affiliationType?: string;
  planValidityDate?: string;
  promoterId?: number;
  firstName?: string;
  lastName?: string;
  userEmail?: string;
  phoneNumber?: string;
  type?: string;
  documentType?: string;
  documentNumber?: string;
  cuit?: string;
  state?: string;
  emailConfirmation: string;
  user: User;
  id?: number;
  hiringMode?: string;
  increasePlanAllowed?: string;
  companyPlan?: string;
  isPaymentResponsible?: string;
  details?: string;
  applicationType?: string;
  rpaStatus?: boolean;
  rpaPath?: string;
  planTypes?: string;
  datosTitularContrato?: any;
  attachment?: Array<Record<string, number>>;
  preddjjId?: number;
  applicationSubtype?: string;
}

export interface SolicitudBinariaReqForm {
  id?: number;
  firstname?: string;
  lastname?: string;
  dni?: string;
  mail?: string;
  mailConfirmation: string;
  phoneNumber?: string;
  productId?: string;
  typeProductId?: number;
  promoterId?: number;
  cotizacionId?: number;
  forms?: Array<Record<string, number>>;
}

export interface SolicitudBinaria {
  id?: number;
  promoterId: number;
  productName?: string;
  typeProductName?: string;
  state: string;
  firstname?: string;
  lastname?: string;
  dni?: string;
  stateDatetime?: string;
  forms: any;
  documents: any;
  links: [any];
  actions: [any];
  nextstate: string;
}

export interface SolicitudBinariaRequest extends SolicitudBinaria {
  id: number;
}

export interface SolicitudON {
  affiliationType?: string;
  planValidityDate?: string;
  promoterId?: number;
  firstName?: string;
  lastName?: string;
  userEmail?: string;
  phoneNumber?: string;
  type?: string;
  documentType?: string;
  documentNumber?: string;
  state: string;
  stateDatetime?: string;
  applicationType?: string;
  user: User;
  id?: number;
  hiringMode?: string;
  companyName?: string;
  companyCuit?: string;
  actions: [any];
  f1Document?: string;
  swornDocument?: string;
  attachments?: string[];
  entryChannel?: string;
  familyGroupDetails?: FamilyMember[];
  potentialRisk?: boolean;
  isPaymentResponsible?: string;
}

export interface SolicitudONRequestForm {
  affiliationType?: string;
  planValidityDate?: string;
  promoterId?: number;
  firstName?: string;
  lastName?: string;
  userEmail?: string;
  phoneNumber?: string;
  type?: string;
  documentType?: string;
  documentNumber?: string;
  state: string;
  user: User;
  id?: number;
  hiringMode?: string;
  companyName?: string;
  companyCuit?: string;
  entryChannel: string;
  isPaymentResponsible?: string;
}

export interface SolicitudONRequest extends SolicitudON {
  id: number;
}

export interface SolicitudF2RequestForm {
  id?: number;
  companyName?: string;
  cuit?: string;
  planValidityDate?: string;
  promoterId?: number;
  state: string;
  rpaStatus?: boolean;
  rpaPath?: string;

  // user fields
  attorney: Attorney;
  documentType?: string;
  documentNumber?: string;
  userEmail?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;

  promoter: Promoter;
}

export interface SolicitudF2 extends SolicitudF2RequestForm {
  stateDatetime?: string;
  actions: [any];
  f2?: string[];
  termsAndConditions?: string[];
  formData?: string[];
  attachments?: string[];
  docsOthers?: string[];
  zipGawUrl?: string;
  lastAudit?: string;
}

export interface SolicitudF2Request extends SolicitudF2 {
  id: number;
  entryChannel?: string;
  vbSteps?: IVBSteps;
}

export type i18nStringType = string | object | (string | object)[];

type Key = string | number;
export type GenericObjectInterface<T> = {
  [key in Key]: T;
};

export enum FamilyMembers {
  PARTNER = 'partner',
  CHILD = 'child',
  HIJO = 'hijo',
  PROSPECT = 'prospect',
  STEPSON = 'stepson',
  SON_UNDER_GUARD = 'son_under_guard'
}

export enum Roles {
  ADMIN = 'admin',
  REGULAR = 'regular',
  SUPER_ADMIN = 'superadmin',
}

export enum SubRoles {
  SUPER_ADMIN = 'superadmin',
  OSDE = 'osde',
  BINARIA = 'binaria',
  ON = 'on',
  ANALISTA = 'analista',
  ASESOR = 'asesor'
}

export interface Promoter {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  admin: boolean;
  active: boolean;
  role: Roles;
  capCode: number;
  capDescription: string;
  filialCode: number;
  filialDescription: string;
  subroles: string[];
}

export interface PromoterForm {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: Roles;
  capCode?: number | string;
  capDescription: string;
  filialCode: number | string;
  filialDescription: string;
  permisoOSDE: boolean;
  permisoBinaria: boolean;
  promoter_email: string;
  phoneNumber: number;
  subroles?: string[]
}
export interface FilterAux {
  role: string,
  permisoOSDE: boolean,
  permisoBinaria: boolean,
  filialCode: number | string;
  capCode?: number | string;
}

export enum EntryChannel {
  AUTOGESTION = 'AutogestionEmp',
  BACKOFFICE = 'backoffice'
}

export interface UserPromoter {
  id: number;
  first_name: string;
  last_name: string;
  filial_code: number;
  filial_description: string;
  cap_code: number;
  cap_description: string;
  role: string;
  email: string;
  active: boolean;
  subroles: string[];
}

export interface MassiveUploadRequest {
  id: number;
  nombreEmpresa: string;
  idPromotor: number;
  fechaAlta: string;
  cantidadAltas: number;
  cantProcesadosOK: number;
  cantProcesadosError: number;
  estado: string;
  promoterFirstName: string;
  promoterLastName: string;
}
declare global {
  interface Window {
    config: any;
  }
}
