import React, { useEffect } from 'react';
import { SPDeclarada, Bajo, Mediano, MedianoAlto, Alto, MuyAlto } from '../../../../../../assets/icons_scoring_rate';

const LABEL_TO_CONFIG = {
    "sp declarada": {
        color: "#121A52", icon: <SPDeclarada />, label: "SP Declarada",
    },
    "bajo": {
        color: "#004A1E", icon: <Bajo />, label: "Bajo",
    },
    "mediano": {
        color: "#7C6600", icon: <Mediano />, label: "Mediano",
    },
    "mediano/alto": {
        color: "#854300", icon: <MedianoAlto />, label: "Mediano/Alto",
    },
    "alto": {
        color: "#870F00", icon: <Alto />, label: "Alto",
    },
    "muy alto": {
        color: "#870F00", icon: <MuyAlto />, label: "Muy Alto",
    },
} as const;

const adapter = (key: string, id: string) => {
    const scoringAppURL = window.config.REACT_APP_SCORING_RATE_BASE_URL;
    const lowerCaseKey = key.toLowerCase();
    if (lowerCaseKey in LABEL_TO_CONFIG) {
        return {
            ...LABEL_TO_CONFIG[lowerCaseKey as keyof typeof LABEL_TO_CONFIG],
            url: `${scoringAppURL}/${id}`
        };
    }
    return { color: '#808080', icon: '', label: 'Indefinido', fillPercentage: 0, url: '' };
};

interface IndicatorBallProps {
    scoreResult: string;
    id: string;
}

const IndicatorBall: React.FC<IndicatorBallProps> = ({ scoreResult, id }) => {
    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, []);

    const config = adapter(scoreResult, id);

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.stopPropagation();
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <a
                    href={config.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLinkClick}
                    title="Ver detalle de impacto"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        color: config.color,
                    }}
                >
                    {config.icon}
                    <div
                        style={{
                            marginLeft: '10px',
                            color: config.color,
                            fontFamily: 'Nunito Sans',
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }}
                    >
                        {config.label}
                    </div>
                </a>
            </div>
        </>
    );
};

export default IndicatorBall;
